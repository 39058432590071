import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faClipboardList, faCalendarAlt, faUser, faPlus, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

const AdminMenu = [
  { name: 'Orders', path: '', icon: faClipboardList }
];

const TherapyMenu = [
  { name: 'Appointments', path: '', icon: faClipboardList },
  { name: 'Calendar', path: 'calendar', icon: faCalendarAlt },
  { name: 'Profile', path: 'profile', icon: faUser }
];


const PatientMenu = [
  { name: 'My Therapies', path: '', icon: faPeopleGroup },
  { name: 'Calendar', path: 'calendar', icon: faCalendarAlt },
  { name: 'New Appointment', path: '#', icon: faPlus },
  { name: 'Profile', path: 'profile', icon: faUser }
];

export default function Dashboard({ isAdmin, isPatient }) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.clear();
    isAdmin ? navigate('/admin/login') : navigate('/');
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menu = isAdmin ? AdminMenu : (isPatient ? PatientMenu: TherapyMenu);

  return (
    <div className="relative flex">
      <div className={`bg-gray-800 text-white p-4 transition-all duration-300 ${isOpen ? 'w-64' : 'w-0'} overflow-hidden`}>
        <h2 className={`text-2xl font-bold mb-6 ${isOpen ? 'block' : 'hidden'}`}>Dashboard</h2>
        <nav className={isOpen ? 'block' : 'hidden'}>
          <ul className="space-y-4">
            {menu.map(({ name, path, icon }) => (
              <li key={name}>
                <Link to={path} className="block px-4 py-2 rounded hover:bg-gray-700 flex items-center">
                  <FontAwesomeIcon icon={icon} className="mr-2" />
                  {name}
                </Link>
              </li>
            ))}
            <li>
              <button onClick={onLogout} className="block px-4 py-2 rounded hover:bg-gray-700 flex items-center">
                <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                Logout
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <button
        onClick={toggleMenu}
        className={`absolute top-1/2 -right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-l focus:outline-none`}
      >
        <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
      </button>
    </div>
  );
}
