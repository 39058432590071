const isDev = process.env.NODE_ENV === "development";
const BASE_URL = isDev ? "http://localhost:3333" : "https://api.icarewellbeing.com";

class HttpService {
  async getJWT() {
    const token = await localStorage.getItem("token");
    console.log("getjwt token", token);
    return token ?? null;
  }

  logout() {
    localStorage.clear();
    window.location.href = "/";
  }

  getCredential () {
    return isDev ? { credentials: "omit" } : { credentials: "include" };
  }

  async get(path) {
    const token = await this.getJWT();
    const result = await fetch(`${BASE_URL}/${path}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      ...this.getCredential(),
    });

    if (result.status === 401) {
      return this.logout();
    }
    const response = await result.json();
    if (response.ok) {
      return response;
    }
    return response.message;
  }

  async post(path, body) {
    const token = await this.getJWT();
    const authHeader = token ?{ authorization: `Bearer ${token}`} : null;
    const result = await fetch(`${BASE_URL}/${path}`, {
      method: "POST",
      headers: {
        ...authHeader,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      ...this.getCredential(),
    });
    if (result.status === 401) {
      this.logout();
    }
    const response = await result.json();
    if (response.token || response.ok) {
      return response;
    }
    return response.message;
  }
  async put(path, body) {
    const token = await this.getJWT();
    const authHeader = token ?{ authorization: `Bearer ${token}`} : null;
    const result = await fetch(`${BASE_URL}/${path}`, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        ...authHeader,
        "Content-Type": "application/json",
      },
      ...this.getCredential(),
    });
    if (result.status === 401) {
      this.logout();
    }
    const response = await result.json();
    if (response.ok) {
      return response;
    }
    return response.message;
  }
}

export const httpService = new HttpService();
