import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { format, parse, startOfWeek, getDay } from "date-fns";
import { enUS } from "date-fns/locale";
import { CustomToolbar } from "./calendarToolBar";
import { orderStatus } from "../../../../utils/constants";
import Modal from "../../../../components/Modal";

const locales = {
  en: enUS,
};

const calendarLocalizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }),
  getDay,
  locales,
});

const CalendarComponent = ({ events, height }) => {
  const [view, setView] = useState(Views.WEEK);
  const [date, setDate] = useState(new Date());
  const [eventsData, setEventsData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // State for selected event
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  useEffect(() => {
    const filteredEvents = events.filter((eve) => eve !== undefined);
    setEventsData(filteredEvents);
  }, [events]);

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleNavigate = (newDate, navigationType) => {
    setDate(newDate);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor:
          event.status === orderStatus.CONFIRMED ? "#ffb" : "#ffb4b1",
        color: "black",
        cursor: "pointer",
      },
    };
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };



  return (
    <div>
      <Calendar
        localizer={calendarLocalizer}
        events={eventsData}
        startAccessor="start"
        endAccessor="end"
        date={date}
        view={view}
        onNavigate={handleNavigate}
        onView={handleViewChange}
        style={{ height }}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={handleSelectEvent}
        components={{
          toolbar: CustomToolbar,
        }}
      />
      {showModal && (
        <Modal onClose={handleCloseModal}>
          <h2 className="text-xl font-bold mb-4">{selectedEvent.title}</h2>

          {selectedEvent?.name && (
            <>
            <p>
              <strong>Therapy :</strong> {selectedEvent?.name}
            </p>
            <p>
                <strong>email:</strong>{" "}
                {selectedEvent?.email}
            </p>
            </>
          )
        }

          <p>{selectedEvent.desc}</p>
          <p>
            <strong>Start:</strong> {selectedEvent.start.toString()}
          </p>
          <p>
            <strong>End:</strong> {selectedEvent.end.toString()}
          </p>
          <span>
            source of the appointment: 
            {selectedEvent.isIcal ? "google" : "System"}
          </span>
        </Modal>
      )}
    </div>
  );
};

export default CalendarComponent;
