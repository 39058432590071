import React, { useState, useEffect } from "react";
import { httpService } from "../../services/httpsService";
import TableOrders from "./component/TableOrders";
import Loader from "../../components/Loader";

export default function Orders() {
  const [ordersFiveMine, setOrdersFiveMine] = useState([]);
  const [ordersSameDay, setOrdersSameDay] = useState([]);
  const [beforeToDayOrders, setBeforeToDayOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPageFiveMine, setCurrentPageFiveMine] = useState(1);
  const [totalPagesFiveMine, setTotalPagesFiveMine] = useState(1);
  const [currentPageSameDay, setCurrentPageSameDay] = useState(1);
  const [totalPagesSameDay, setTotalPagesSameDay] = useState(1);
  const [currentPageOld, setCurrentPageOld] = useState(1);
  const [totalPagesOld, setTotalPagesOld] = useState(1);
  const limit = 10;

  const getSessionsNumber = (amount) => {
    if (amount === 90) return "1 Session";
    if (amount === 324) return "4 Sessions";
    return "8 Sessions";
  };

  const prepareOrders = (orders) => {
    return orders.map((o) => ({
      orderId: o.order.orderId,
      orderType: "Counseling",
      startDateTime: o?.order.startDateTime
        ? o?.order.startDateTime.map((d) => new Date(d).toUTCString())
        : [],
      patientName: o?.patient?.name,
      numberOfSessions: getSessionsNumber(o.order.amount),
      status: o.order.status,
      tel: o?.tel,
      patientName: o.name,
      data: o.data,
      isFiveMinute: o.order.fiveMinuteOrder,
    }));
  };

  const fetchFiveMinuteOrders = async () => {
    // setIsLoading(true);
    try {
      const result = await httpService.get(
        `api/orders/five-minute/${currentPageFiveMine}/${limit}`
      );
      if (result.ok && result.data) {
        setOrdersFiveMine(prepareOrders(result.data.fiveMinutesOrders));
        setTotalPagesFiveMine(result.data.totalPages);
      }
    } catch (error) {
      console.error("Error fetching five-minute orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSameDayOrders = async () => {
    // setIsLoading(true);
    try {
      const result = await httpService.get(
        `api/orders/same-day/${currentPageSameDay}/${limit}`
      );
      if (result.ok && result.data) {
        setOrdersSameDay(prepareOrders(result.data.sameDayOrders));
        setTotalPagesSameDay(result.data.totalPages);
      }
    } catch (error) {
      console.error("Error fetching same-day orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOldOrders = async () => {
    // setIsLoading(true);
    try {
      const result = await httpService.get(
        `api/orders/old/${currentPageOld}/${limit}`
      );
      if (result.ok && result.data) {
        setBeforeToDayOrders(prepareOrders(result.data.oldOrders));
        setTotalPagesOld(result.data.totalPages);
      }
    } catch (error) {
      console.error("Error fetching old orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChangeFiveMine = (page) => {
    setCurrentPageFiveMine(page);
  };

  const handlePageChangeSameDay = (page) => {
    setCurrentPageSameDay(page);
  };

  const handlePageChangeOld = (page) => {
    setCurrentPageOld(page);
  };

  useEffect(() => {
    fetchFiveMinuteOrders();
  }, [currentPageFiveMine]);

  useEffect(() => {
    fetchSameDayOrders();
  }, [currentPageSameDay]);

  useEffect(() => {
    fetchOldOrders();
  }, [currentPageOld]);

  if (isLoading) return <Loader />;

  return (
    <div className="p-4 flex flex-col space-y-4">
      <div className="border-b-2">
        <h1 className="text-2xl font-bold mb-4">Five minute orders (Today)</h1>
        <TableOrders setOrders={setOrdersFiveMine} orders={ordersFiveMine} />

        <div className="flex justify-center mt-4">
          <button
            onClick={() => handlePageChangeFiveMine(currentPageFiveMine - 1)}
            disabled={currentPageFiveMine === 1}
            className="mx-2 px-4 py-2 bg-gray-200 rounded-md"
          >
            Previous
          </button>
          <button
            onClick={() => handlePageChangeFiveMine(currentPageFiveMine + 1)}
            disabled={currentPageFiveMine === totalPagesFiveMine}
            className="mx-2 px-4 py-2 bg-gray-200 rounded-md"
          >
            Next
          </button>
        </div>
      </div>

      <div className="border-2 m-y-4" />

      <div className="border-b-2">
        <h1 className="text-2xl font-bold mb-4">Same day orders</h1>
        <TableOrders setOrders={setOrdersSameDay} orders={ordersSameDay} />

        <div className="flex justify-center mt-4">
          <button
            onClick={() => handlePageChangeSameDay(currentPageSameDay - 1)}
            disabled={currentPageSameDay === 1}
            className="mx-2 px-4 py-2 bg-gray-200 rounded-md"
          >
            Previous
          </button>
          <button
            onClick={() => handlePageChangeSameDay(currentPageSameDay + 1)}
            disabled={currentPageSameDay === totalPagesSameDay}
            className="mx-2 px-4 py-2 bg-gray-200 rounded-md"
          >
            Next
          </button>
        </div>
      </div>

      <div className="border-2 m-y-4" />

      <div className="border-b-2">
        <h1 className="text-2xl font-bold mb-4">Old orders</h1>
        <TableOrders
          setOrders={setBeforeToDayOrders}
          orders={beforeToDayOrders}
        />

        <div className="flex justify-center mt-4">
          <button
            onClick={() => handlePageChangeOld(currentPageOld - 1)}
            disabled={currentPageOld === 1}
            className="mx-2 px-4 py-2 bg-gray-200 rounded-md"
          >
            Previous
          </button>
          <button
            onClick={() => handlePageChangeOld(currentPageOld + 1)}
            disabled={currentPageOld === totalPagesOld}
            className="mx-2 px-4 py-2 bg-gray-200 rounded-md"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
