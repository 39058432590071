import React, { useState } from "react";
import { httpService } from "../../../services/httpsService";
import { orderStatus } from "../../../utils/constants";

export default function TableOrders({ orders, setOrders, setCurrentPage, currentPage }) {
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage] = useState(5);


  const handleOrderStatus = async (e, order, status) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      const result = await httpService.put("api/update-order-status", {
        orderId: order.orderId,
        status,
      });

      if (result && result.data && result.data.status) {
        if (result.data.status === orderStatus.REJECTED) {
          setOrders((prevOrders) =>
            prevOrders.filter(
              (prevOrder) => prevOrder.orderId !== order.orderId
            )
          );
        } else {
          setOrders((prevOrders) =>
            prevOrders.map((prevOrder) =>
              prevOrder.orderId === order.orderId
                ? { ...prevOrder, status: result.data.status }
                : prevOrder
            )
          );
        }
      } else {
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = orders.slice(indexOfFirstRow, indexOfLastRow);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(orders.length / rowsPerPage); i++) {
    pageNumbers.push(i);
  }
  const renderPageNumbers = () => {
    const totalPages = Math.ceil(orders.length / rowsPerPage);
    let start = Math.max(1, currentPage - 1);
    let end = Math.min(start + 3, totalPages);
    if (end - start < 3) {
      start = Math.max(1, end - 3);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(
        <li key={i}>
          <a
            href="#"
            onClick={(e) => handleClick(e, i)}
            className={`block size-8 rounded border text-center leading-8 ${
              currentPage === i ? "border-blue-600 bg-blue-600 text-white" : ""
            }`}
          >
            {i}
          </a>
        </li>
      );
    }
    return pages;
  };

  const handleClick = (event, number) => {
    event.preventDefault();
    setCurrentPage(number);
  };

  const renderPrevNextButton = (direction) => {
    let disabled = false;
    let targetPage = currentPage;

    if (direction === "prev") {
      disabled = currentPage === 1;
      targetPage = currentPage - 1;
    } else if (direction === "next") {
      disabled = currentPage === Math.ceil(orders.length / rowsPerPage);
      targetPage = currentPage + 1;
    }

    return (
      <li>
        <button
          disabled={disabled}
          href="#"
          onClick={(e) => handleClick(e, targetPage)}
          className={`inline-flex size-8 items-center justify-center rounded border border-gray-100 bg-white text-gray-900 rtl:rotate-180 ${
            disabled ? "cursor-not-allowed" : ""
          }`}
        >
          <span className="sr-only">
            {direction === "prev" ? "Prev Page" : "Next Page"}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3 w-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d={
                direction === "prev"
                  ? "M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  : "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              }
              clipRule="evenodd"
            />
          </svg>
        </button>
      </li>
    );
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Date
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Therapy
              </th>
               <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Email
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                N.Sessions
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Status
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentRows.map((order, index) => (
              <tr
                key={index}
                className="odd:bg-gray-50"
              >
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.startDateTime.map(d => <span>{d}</span>)}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.name}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.email}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.numberOfSessions}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.status}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.status === "in progress" ? (
                      <button
                        onClick={(e) =>
                          handleOrderStatus(e, order, orderStatus.CONFIRMED)
                        }
                        disabled={isLoading}
                        className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                      >
                        Request Therapy
                      </button>
                  ) :
                    order.status === 'confirmed' 
                    ? (<button
                        onClick={(e) =>
                          handleOrderStatus(e, order, orderStatus.REJECTED)
                        }
                        disabled={isLoading}
                        className="bg-red-500 text-white px-4 py-2 rounded"
                      >
                        Change Therapy
                      </button>)
                   :
                   <span>-</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ol className="flex justify-center gap-1 text-xs font-medium mt-4">
        {renderPrevNextButton("prev")}
        {renderPageNumbers()}
        {renderPrevNextButton("next")}
      </ol>
    </>
  );
}
