import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Register from './therapy/auth/Register';
import AdminLogin from './admin/auth/Login';
import Therapy from './therapy';
import Patient from './patient';
import Admin from './admin';
import ResetPasswordForm from './therapy/auth/ResetPasswordForm';
import ForgotPassword from './therapy/auth/ForgotPassword';
import "react-toastify/dist/ReactToastify.css";
import Login from './Login';

export default function Home() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPasswordForm />} />
      <Route path="/therapy/*" element={<Therapy />} />
      <Route path="/patient/*" element={<Patient />} />
      <Route path="/admin/*" element={<Admin />} />
    </Routes>
  );
}
