import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Orders from './dashboard/Orders';
import Dashboard from '../../components/DashboardMenu';

export default function Index() {
  return (
    <div className="flex h-screen w-full">
      <Dashboard isAdmin={true} />
      <div className="flex-1 p-4">
        <Routes>
          <Route path="/" element={<Orders />} />
        </Routes>
      </div>
    </div>
  );
}
