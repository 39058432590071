import React from "react";

const Drawer = ({ setOpen, open, title = null, children = null }) => {
  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <>
      {open && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-10"
          onClick={closeDrawer}
          aria-hidden="true"
        ></div>
      )}
      <div
        className={`fixed inset-y-0 right-0 z-20 bg-white shadow-lg transition-transform transform ${
          open ? "translate-x-0" : "translate-x-full"
        }`}
        style={{ width: "100%", maxWidth: "40rem" }}
        role="dialog"
        aria-modal="true"
        aria-labelledby="drawer-title"
      >
        <span
          onClick={closeDrawer}
          className="absolute text-gray-500 cursor-pointer hover:text-black right-4 text-2xl font-bold top-0"
        >
          x
        </span>
        <div className="p-4 h-full overflow-y-auto">
          <h2 id="drawer-title" className="text-xl font-bold mb-4">
            {title}
          </h2>
          <div className="space-y-4">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Drawer;
