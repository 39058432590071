import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader"; // Import the Loader component
import { httpService } from "../../services/httpsService";
import { ToastContainer, toast } from "react-toastify";
import { isValidICalURL } from "../../utils/constants";

export default function Profile() {
  const [icalUrl, setIcalUrl] = useState("");
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isAvailable, setIsAvailable] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await httpService.get("api/get-therapy");

        if (result.ok && result.data && result.data.email) {
          setEmail(result.data.email);
          setIcalUrl(result.data.icalUrl);
        } else {
          console.error("Unexpected response format:", result);
        }
      } catch (error) {
        console.error("Error fetching therapy:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  
  const handleSubmit = async (e) => {
      e.preventDefault();
    if(!isValidICalURL(icalUrl)){
        return  toast.error('ical URL not valid URL');
    }

    try {
      const result = await httpService.put("api/update-therapy-profile", {
        email: email,
        oldPassword: oldPassword,
        newPassword: newPassword,
        isAvailable: isAvailable,
        icalUrl: icalUrl,
      });

      if (result.ok && result.message) {
        toast.success(result.message);
        setNewPassword("");
        setOldPassword("");
      } else {
        toast.error(result);
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error updating therapy profile:", error);
    }
  };

  
  if (loading) {
    return <Loader />;
  }

  return (
    <div className="max-w-lg mx-auto bg-white shadow-md rounded-lg p-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="icalUrl"
            className="block text-sm font-medium text-gray-700"
          >
            iCal URL
          </label>
          <input
            type="text"
            id="icalUrl"
            value={icalUrl}
            onChange={(e) => setIcalUrl(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>

        <div>
          <label
            htmlFor="oldPassword"
            className="block text-sm font-medium text-gray-700"
          >
            Old Password
          </label>
          <input
            type="password"
            id="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>

        <div>
          <label
            htmlFor="newPassword"
            className="block text-sm font-medium text-gray-700"
          >
            New Password
          </label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>

        <div className="flex flex-row space-x-4 w-full items-center">
          <input
            type="checkbox"
            id="isAvailable"
            checked={isAvailable}
            onChange={(e) => setIsAvailable(e.target.checked)}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
          <label
            htmlFor="isAvailable"
            className="ml-2 block text-sm text-gray-900"
          >
            Available
          </label>
        </div>

        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </form>
      <ToastContainer />
      
    </div>
  );
}
