import { Views } from 'react-big-calendar'
export const ToolBarButton = ({ type, view, navigateButton, label, onPress, navigation }) => {
  const getNavigationButtonStyle = navigationType => (navigateButton === navigationType ? 'bg-gray-200' : 'bg-white hover:bg-gray-100')
  const getViewButtonStyle = viewType => (viewType === view ? 'bg-gray-200' : 'bg-white hover:bg-gray-100')

  if (navigation) {
    return (
      <button
        key={type}
        className={`${getNavigationButtonStyle(type)} px-4 py-2 ${type === 'previous' ? 'rounded-l-lg' : type === 'next' ? 'rounded-r-lg' : ''}`}
        onClick={() => onPress(type)}
      >
        {label}
      </button>
    )
  }
  return (
    <button
      key={type}
      className={`${getViewButtonStyle(Views[type.toUpperCase()])} px-4 py-2 ${type === 'month' ? 'rounded-l-lg' : type === 'day' ? 'rounded-r-lg' : ''}`}
      onClick={() => onPress(type)}
    >
      {label}
    </button>
  )
}
