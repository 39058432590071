import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { httpService } from '../services/httpsService';

export default function UserLogin({disabled, isPatient}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const getToken = async() => {
    const token = await localStorage.getItem('token');
    return token;
  }

  useEffect(() => {
    getToken().then(async(token) => {
     //console.log('token', token)
     if(token) return isPatient ?  navigate('/patient') : navigate('/therapy');
     await localStorage.removeItem('token');
    });
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      //console.log(' email, password, isPatient', email, password, isPatient)
      const result = await httpService.post('api/login',{
        login: email, password, isPatient
      });
      //console.log(result)
      // Store the token in local storage or cookie
      if(result.token){
        await localStorage.setItem('token', result.token);
        return isPatient ? navigate('/patient') : navigate('/therapy')
      }else {
        setError(result);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex items-center justify-center w-full font-serif">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
        <h2 className="text-2xl font-bold mb-6 text-center">{isPatient ? `Patient` : 'Therapist'} Login</h2>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              {isPatient ? 'Phone Number' : 'Email'}
            </label>
            <input
             disabled={disabled}
              type={isPatient ? 'tel' : 'email'}
              id="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
             disabled={disabled}
              type="password"
              id="password"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="mt-2">
              <Link to="/forgot-password" className="text-sm text-blue-500 hover:text-blue-800">
                Forgot Password?
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <button
             disabled={disabled}
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/2"
            >
              Login
            </button>
           {isPatient && <Link to={'/register'}>Create new account?</Link>}
          </div>
        </form>
      </div>
    </div>
  );
}
