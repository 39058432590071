export const orderStatus = {
  CONFIRMED: "confirmed",
  REJECTED: "rejected",
  IN_PROGRESS: "in progress",
};

export function isValidICalURL(url) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
      "((([a-zA-Z0-9\\-\\_]+)\\.)+[a-zA-Z]{2,})" + // domain name and extension
      "(\\:\\d+)?(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*" + // port and path
      "(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*\\.ics(\\?[;&a-zA-Z0-9%_\\.=~\\-\\+]*)?(\\#[-a-zA-Z0-9_\\+~]*)?$" // iCal file extension, query string, and fragment locator
  );

  return pattern.test(url);
}