import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Orders from './Orders';
import Calendar from './Calendar';
import Dashboard from '../../components/DashboardMenu';
import Profile from './Profile';

export default function Index() {
  return (
    <div className="flex h-screen w-full">
      <Dashboard isPatient={true} />
      <div className="flex-1 p-4">
        <Routes>
          <Route path="/" element={<Orders />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>
    </div>
  );
}
