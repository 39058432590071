import React from "react";

const CustomTable = ({
  headers,
  data,
  title,
  handlePageChange,
  currentPage,
  totalPages,
  loading,
}) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm border border-gray-200">
        <caption className="text-lg font-bold text-gray-900 mb-2 text-left">
          {title}
        </caption>
        <thead className="ltr:text-left rtl:text-right">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="whitespace-nowrap px-4 py-2 font-medium text-gray-900 border-b"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={`${rowIndex % 2 === 0 ? "odd:bg-gray-50" : ""}`}
            >
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className="whitespace-nowrap px-4 py-2 text-gray-700 border-b"
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center items-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className={`text-sm px-3 py-1 mx-1 rounded-md focus:outline-none ${
            currentPage === 1 || loading
              ? "bg-gray-300 text-gray-700 cursor-not-allowed"
              : "bg-blue-600 text-white"
          }`}
          disabled={currentPage === 1 || loading}
        >
          Prev
        </button>
        <span className="text-sm px-3 py-1 mx-1">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className={`text-sm px-3 py-1 mx-1 rounded-md focus:outline-none ${
            currentPage === totalPages || loading
              ? "bg-gray-300 text-gray-700 cursor-not-allowed"
              : "bg-blue-600 text-white"
          }`}
          disabled={currentPage === totalPages || loading}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CustomTable;
