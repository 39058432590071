import React, { useState, useEffect } from "react";
import { httpService } from "../../services/httpsService";
import TableOrders from "./component/TableOrders";
import Loader from "../../components/Loader";

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const getOrders = async () => {
    setIsLoading(true);
    const result = await httpService.get("patient/orders/"+(currentPage-1));
    let allOrders = [];
    await result.data.map((res) => {
      const order = prepareOrders(res);
      allOrders.push(order);
    });
    setIsLoading(false);
    setOrders(allOrders);
  };

  const getSessionsNumber = (amount) => {
    if (amount === 90) return "1 Session";
    if (amount === 324) return "4 Sessions";
    return "8 Sessions";
  };

  const prepareOrders = (orders) => {
    return {
      orderId: orders.orderId,
      orderType: "Counseling",
      startDateTime: orders.startDateTime.map((d) =>
        new Date(d).getDate() +'-'+(new Date(d).getMonth() + 1).toString().padStart(2, '0') +'-'+ new Date(d).getFullYear()
      ),
      name: orders.name,
      email: orders.email,
      numberOfSessions: getSessionsNumber(orders.amount),
      status: orders.status,
      tel: orders.tel,
      isFiveMinute: orders.fiveMinuteOrder,
      ical: orders.icalUrl,
    };
  };


  useEffect(() => {
    getOrders();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="p-4 flex flex-col space-y-4">
      <div className="border-b-2">
        <h1 className="text-2xl font-bold mb-4">My Orders</h1>
        <TableOrders currentPage={currentPage} setCurrentPage={setCurrentPage} setOrders={setOrders} orders={orders} />
      </div>
    </div>
  );
}
