import React, { useEffect, useState } from "react";
import CalendarComponent from "./component/bigCalendar/calendarView";
import { parseISO } from "date-fns";
import { httpService } from "../../services/httpsService";

const convertToISO = (dateTime) => {
  // Convert the date string to ISO format
  const date = new Date(dateTime);
  return date.toISOString();
};

const parseDate = (dateTime) => {
  const isoDate = convertToISO(dateTime);
  const parsedDate = parseISO(isoDate);
  return new Date(
    parsedDate.getFullYear(),
    parsedDate.getMonth(),
    parsedDate.getDate(),
    parsedDate.getHours(),
    parsedDate.getMinutes()
  );
};

const addOneHour = (date) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 1);
  return newDate;
};


const getEventsFromReservation = (events) => {
  console.log(events)
  let processedEvents = [];
  events.forEach((eve) => {
    eve.startDateTime.forEach((date) => {
      const startDate = parseDate(date);
      const endDate = addOneHour(startDate);
      processedEvents.push({
        email:eve.email,
        orderId: eve.orderId,
        name: eve.name,
        title: eve.status,
        start: startDate,
        end: endDate,
        allDay: false,
        desc: "",
        isIcal: false,
        status: eve.status,
      });
    });
  });

  return processedEvents;
};


export default function Calendar() {
  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    const result = await httpService.get("patient/orders/0");;
    let events = [];
    await result.data.map((res) => {
      events.push(res);
      setEvents(events);
    });
  };


  useEffect(() => {
    getEvents();
  }, []);

  console.log(getEventsFromReservation(events)[0]);
  const bookingOrders = getEventsFromReservation(events);

  return (
    <div>
      <CalendarComponent height={700} events={bookingOrders} />
    </div>
  );
}
