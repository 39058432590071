import React, { useState, useEffect } from "react";
import CustomTable from "../../components/CustomTable";
import { httpService } from "../../../services/httpsService";

const Orders = () => {
  // --------------states---------------
  const [assignedOrders, setAssignedOrders] = useState([]);
  const [notAssignedOrders, setNotAssignedOrders] = useState([]);
  const [therapists, setTherapists] = useState([]);
  const [currentPageAssignedOrders, setCurrentPageAssignedOrders] = useState(1);
  const [totalPagesAssignedOrders, setTotalPagesAssignedOrders] = useState(1);
  const [currentPageNotAssignedOrders, setCurrentPageNotAssignedOrders] =
    useState(1);
  const [totalPagesNotAssignedOrders, setTotalPagesNotAssignedOrders] =
    useState(1);
  const limit = 10;
  const [loadingPageNotAssignedOrders, setLoadingPageNotAssignedOrders] =
    useState(false);
  const [loadingPageAssignedOrders, setLoadingPageAssignedOrders] =
    useState(false);
// --------------functions---------------
  const fetchNotAssignedOrders = async (page = 1) => {
    try {
      setLoadingPageNotAssignedOrders(true);
      const response = await httpService.get(
        `api/admin/orders/not-assigned/${page}/${limit}`
      );
      if (response.ok && response.data) {
        const { notAssignedOrders, totalPages, currentPage } = response.data;
        setNotAssignedOrders(notAssignedOrders);
        setTotalPagesNotAssignedOrders(totalPages);
        setCurrentPageNotAssignedOrders(currentPage);
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setLoadingPageNotAssignedOrders(false);
    }
  };
  const fetchAssignedOrders = async (page = 1) => {
    try {
      setLoadingPageAssignedOrders(true);
      const response = await httpService.get(
        `api/admin/orders/assigned/${page}/${limit}`
      );
      if (response.ok && response.data) {
        const { assignedOrders, totalPages, currentPage } = response.data;
        setAssignedOrders(assignedOrders);
        setTotalPagesAssignedOrders(totalPages);
        setCurrentPageAssignedOrders(currentPage);
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setLoadingPageAssignedOrders(false);
    }
  };
  const fetchTherapies = async () => {
    try {
      const therapistsResponse = await httpService.get("api/get-therapies");
      if (therapistsResponse.ok && therapistsResponse.data) {
        setTherapists(therapistsResponse.data);
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetchTherapies();
        fetchNotAssignedOrders();
        fetchAssignedOrders();
      } catch (err) {
        console.log("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  const assignTherapy = async (orderId, therapistId) => {
    try {
      const response = await httpService.post("api/admin/assign-therapy", {
        orderId,
        therapistId,
      });

      const updatedAssignedOrders = [...assignedOrders];
      const updatedNotAssignedOrders = notAssignedOrders.filter(
        (order) => order.orderId !== orderId
      );

      const assignedOrder = notAssignedOrders.find(
        (order) => order.orderId === orderId
      );

      updatedAssignedOrders.push(assignedOrder);

      setAssignedOrders(updatedAssignedOrders);
      setNotAssignedOrders(updatedNotAssignedOrders);
    } catch (err) {
      console.error("Error assigning therapy:", err);
    }
  };

  const notAssignedRows = notAssignedOrders?.map((order) => [
    order.orderId,
    order.startDateTime
      .map((d) => new Date(d).toUTCString() || "NA")
      .join(" / "),
    order?.patient?.name || "NA",
    order?.patient?.email || "NA",
    order?.patient?.tel || "NA",
    order.isFiveMinute ? "Yes" : "No",
    order.amount,
    <select
      key={order.orderId}
      onChange={(e) => assignTherapy(order.orderId, e.target.value)}
      className="w-full border rounded px-2 py-1"
    >
      <option disabled selected value="">
        Choose a therapy
      </option>
      {therapists?.map((therapist) => (
        <option key={therapist._id} value={therapist._id}>
          {therapist.name ? therapist.name : therapist.email}
        </option>
      ))}
    </select>,
  ]);
  const assignedRows = assignedOrders?.map((order) => [
    order.orderId,
    order.startDateTime.map((d) => (
      <>
        {new Date(d).toUTCString() || "NA"}
        <br></br>
      </>
    )),
    order?.patient?.name || "NA",
    order?.patient?.email || "NA",
    order?.patient?.tel || "NA",
    order.isFiveMinute ? "Yes" : "No",
    order.amount,
  ]);

  const handlePageChangeNotAssignedOrders = (newPage) => {
    if (newPage > 0 && newPage <= totalPagesNotAssignedOrders) {
      fetchNotAssignedOrders(newPage);
    }
  };
  const handlePageChangeAssignedOrders = (newPage) => {
    if (newPage > 0 && newPage <= totalPagesAssignedOrders) {
      fetchAssignedOrders(newPage);
    }
  };

  return (
    <div className="p-4">
      <CustomTable
        title="Orders Not Assigned"
        headers={notAssignedHeaders}
        data={notAssignedRows.reverse()}
        handlePageChange={handlePageChangeNotAssignedOrders}
        currentPage={currentPageNotAssignedOrders}
        totalPages={totalPagesNotAssignedOrders}
        loading={loadingPageNotAssignedOrders}
      />
      <CustomTable
        title="Orders Assigned"
        headers={assignedHeaders}
        data={assignedRows.reverse()}
        handlePageChange={handlePageChangeAssignedOrders}
        currentPage={currentPageAssignedOrders}
        totalPages={totalPagesAssignedOrders}
        loading={loadingPageAssignedOrders}
      />
    </div>
  );
};

export default Orders;

const notAssignedHeaders = [
  "Order ID",
  "Order Start Date",
  "Name",
  "Email",
  "Tele",
  "isFiveMinute",
  "Amount",
  "Assign to",
];
const assignedHeaders = [
  "Order ID",
  "Order Start Date",
  "Name",
  "Email",
  "Tele",
  "isFiveMinute",
  "Amount",
];