import React, { useEffect, useState } from "react";
import CalendarComponent from "./component/bigCalendar/calendarView";
import { parseISO } from "date-fns";
import { httpService } from "../../services/httpsService";

const convertToISO = (dateTime) => {
  // Convert the date string to ISO format
  const date = new Date(dateTime);
  return date.toISOString();
};

const parseDate = (dateTime) => {
  const isoDate = convertToISO(dateTime);
  const parsedDate = parseISO(isoDate);
  return new Date(
    parsedDate.getFullYear(),
    parsedDate.getMonth(),
    parsedDate.getDate(),
    parsedDate.getHours(),
    parsedDate.getMinutes()
  );
};

const addOneHour = (date) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 1);
  return newDate;
};


const getEventsFromReservation = (events) => {
  let processedEvents = [];
  events.forEach((eve) => {
    eve.startDateTime.forEach((date) => {
      if(date && !isNaN(Date.parse(date))){
    const startDate = parseDate(date);
    const endDate = addOneHour(startDate);
      processedEvents.push({
        patient:eve.patient,
        orderId: eve.orderId,
        title: eve.status,
        start: startDate,
        end: endDate,
        allDay: false,
        desc: "id: " + eve.orderId,
        isIcal: false,
        status: eve.status,
      });
      }
    });
  });

  return processedEvents;
};

// manage ical service data manipulation
const getEventsFromIcal = (events) => {
  return events?.map((eve) => {
    return {
      title: eve.summary || "No Title",
      start: parseDate(eve.start),
      end: parseDate(eve.end),
      allDay: eve.allDay || false,
      desc: eve.description || "",
      isIcal: true,
    };
  });
};

export default function Calendar() {
  const [events, setEvents] = useState([]);
  const [ical, setIcal] = useState([]);

  const getEvents = async () => {
    const result = await httpService.get("api/orders");
    let events = [];
    await result.data.map((res) => {
      events.push({ patient: res, ...res.order });
      setEvents(events);
    });
  };

  const getIcal = async () => {
    const result = await httpService.get("api/ical");
    setIcal(result.data);
  };

  useEffect(() => {
    getEvents();
    getIcal();
  }, []);

  const bookingOrders = getEventsFromReservation(events);
  const icalOrders = getEventsFromIcal(ical);

  const orders = bookingOrders.concat(icalOrders);

  return (
    <div>
      <CalendarComponent height={700} events={orders} />
    </div>
  );
}
