import React, { useState } from 'react'
import { Views } from 'react-big-calendar'
import { ToolBarButton } from './toolBarButton'
import 'react-big-calendar/lib/css/react-big-calendar.css'

export const CustomToolbar = ({ label, onNavigate, onView }) => {
  const [view, setView] = useState(Views.WEEK)
  const [navigateButton, setNavigateButton] = useState('today')

  const handleViewChange = newView => {
    setView(newView)
    onView(newView)
  }

  const handleNavigate = navigationType => {
    setNavigateButton(navigationType)

    switch (navigationType) {
      case 'previous':
        onNavigate('PREV')
        break
      case 'next':
        onNavigate('NEXT')
        break
      default:
        onNavigate('TODAY')
    }
  }

  const navigationButtons = [
    { type: 'previous', label: 'previous' },
    { type: 'today', label: "today" },
    { type: 'next', label: 'next' }
  ]

  const viewButtons = [
    { name: 'month', label: 'month' },
    { name: 'week', label: 'week' },
    { name: 'day', label: 'day' }
  ]

  return (
    <div className='flex flex-col items-center w-full mb-6 md:flex-row md:justify-between'>
      <div className='flex flex-row rounded-lg divide-x justify-start border mb-4 md:mb-0'>
        {navigationButtons.map(({ type, label }) => (
          <ToolBarButton key={type} navigation={true} onPress={handleNavigate} type={type} label={label} view={view} navigateButton={navigateButton} />
        ))}
      </div>
      <div className='flex justify-center items-center mb-4 md:mb-0'>
        <span className='text-sm p-4 font-medium md:text-xl md:mt-0'>{label}</span>
      </div>
      <div className='flex flex-row rounded-lg divide-x justify-start border'>
        {viewButtons.map(({ name, label }) => (
          <ToolBarButton key={name} onPress={handleViewChange} type={name} label={label} view={view} navigateButton={navigateButton} />
        ))}
      </div>
    </div>
  )
}
