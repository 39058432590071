import React, { useState, useEffect } from "react";
import { httpService } from "../../../services/httpsService";
import { orderStatus } from "../../../utils/constants";
import OrderUpdate from "./OrderUpdate";

export default function TableOrders({ orders, setOrders }) {
  const [showModal, setShowModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrderUpdate, setSelectedOrderUpdate] = useState(null);

  const openOrderDetails = (data) => {
    setOrderDetails(data);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setOrderDetails([]);
  };

  const handleOrderStatus = async (e, order, status) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      const result = await httpService.put("api/update-order-status", {
        orderId: order.orderId,
        status,
      });

      if (result && result.data && result.data.status) {
        if (result.data.status === orderStatus.REJECTED) {
          setOrders((prevOrders) =>
            prevOrders.filter(
              (prevOrder) => prevOrder.orderId !== order.orderId
            )
          );
        } else {
          setOrders((prevOrders) =>
            prevOrders.map((prevOrder) =>
              prevOrder.orderId === order.orderId
                ? { ...prevOrder, status: result.data.status }
                : prevOrder
            )
          );
        }
      } else {
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Order
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Start at
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Patient
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Tel
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Sessions
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Status
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {orders.map((order, index) => (
              <tr
                key={index}
                // onClick={() => openOrderDetails(order.data)}
                className="odd:bg-gray-50"
              >
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.orderType}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.startDateTime.map((d) => (
                    <span className="block">{d}</span>
                  ))}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.patientName}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.tel}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.numberOfSessions}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.status}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order.status === "in progress" ? (
                    <>
                      <button
                        onClick={(e) =>
                          handleOrderStatus(e, order, orderStatus.CONFIRMED)
                        }
                        disabled={isLoading}
                        className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                      >
                        Accept
                      </button>
                      <button
                        onClick={(e) =>
                          handleOrderStatus(e, order, orderStatus.REJECTED)
                        }
                        disabled={isLoading}
                        className="bg-red-500 text-white px-4 py-2 rounded"
                      >
                        Reject
                      </button>
                    </>
                  ) : order.status === "confirmed" ? (
                    <button
                      onClick={(e) => setSelectedOrderUpdate(order)}
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      Edit
                    </button>
                  ) : (
                    <span>-</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                  Order Details
                </h3>
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="py-2 text-left">Question</th>
                      <th className="py-2 text-left">Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetails.map((item, index) => (
                      <tr key={index} className="border-t">
                        <td className="py-2">{item.question}</td>
                        <td className="py-2">{item.answer}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={closeModal}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <OrderUpdate
        setOrders={setOrders}
        selectedOrderUpdate={selectedOrderUpdate}
        setSelectedOrderUpdate={setSelectedOrderUpdate}
      />
    </>
  );
}
