import React, { useState, useEffect } from "react";
import { httpService } from "../../../services/httpsService";
import Drawer from "../../components/Drawer";

const OrderUpdate = ({
  selectedOrderUpdate,
  setSelectedOrderUpdate,
  setOrders,
}) => {
  const [startDateTimeArray, setStartDateTimeArray] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedOrderUpdate) {
      const formattedDates = selectedOrderUpdate?.startDateTime?.map((date) =>
        new Date(date).toISOString().substring(0, 16)
      );
      setStartDateTimeArray(formattedDates || []);
    }
  }, [selectedOrderUpdate]);

  const updateDateTime = (index, value) => {
    const newArray = [...startDateTimeArray];
    newArray[index] = value;
    setStartDateTimeArray(newArray);
  };

  const removeDateTime = (index) => {
    const newArray = [...startDateTimeArray];
    newArray.splice(index, 1);
    setStartDateTimeArray(newArray);
  };

  const addDateTime = () => {
    setStartDateTimeArray([...startDateTimeArray, ""]);
  };
  console.log(startDateTimeArray);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const result = await httpService.put("api/appointment", {
        id: selectedOrderUpdate.orderId,
        startDateTime: startDateTimeArray.filter((dateTime) => !!dateTime),
      });
      if (result && result.data && result.data.status) {
        setOrders((prevOrders) =>
          prevOrders.map((prevOrder) =>
            prevOrder.orderId === selectedOrderUpdate?.orderId
              ? {
                  ...prevOrder,
                  startDateTime: result.data.startDateTime.map((d) => {
                    return new Date(d).toUTCString();
                  }),
                }
              : prevOrder
          )
        );
      } else {
        console.error("Failed to update dates:", result);
      }
    } catch (error) {
      console.error("Error updating dates:", error);
    } finally {
      setLoading(false);
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    setSelectedOrderUpdate(null);
    setStartDateTimeArray([]);
  };

  return (
    <Drawer
      open={selectedOrderUpdate}
      setOpen={setSelectedOrderUpdate}
      title={"Order Update"}
    >
      <div>
        {startDateTimeArray.map((dateTime, index) => (
          <div key={index} className="flex items-center space-x-4">
            <input
              type="datetime-local"
              className="border border-gray-300 px-3 py-2 rounded-md"
              value={dateTime || ""}
              onChange={(e) => updateDateTime(index, e.target.value)}
            />
            <button
              type="button"
              className="text-red-600 hover:text-red-800 focus:outline-none"
              onClick={() => removeDateTime(index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={addDateTime}
        >
          Add Date
        </button>
      </div>
      <div className="flex justify-end mt-4 absolute bottom-3 right-3">
        <button
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-2 rounded mr-2"
          onClick={closeDrawer}
        >
          Close
        </button>
        <button
          disabled={loading}
          className="bg-green-500 text-white px-4 py-2 rounded"
          onClick={handleSubmit}
        >
          {loading ? "saving..." : "Save Changes"}
        </button>
      </div>
    </Drawer>
  );
};

export default OrderUpdate;
