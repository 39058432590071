import React, { useState } from 'react';
import UserLogin from '../components/UserLogin';

export default function Login() {
  const [isPatient, setIsPatient] = useState(true);

  return (
    <div className="flex flex-col md:flex-row h-screen w-screen bg-gray-100 font-serif">
      {/* Left side - form */}
      <div className="flex flex-col justify-center items-center w-full md:w-2/5 bg-gray-100 p-8 h-full">
        <div className="flex mb-4 w-full">
          <button
            className={`px-4 py-2 mr-2 rounded w-2/3 text-md ${isPatient === false ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
            onClick={() => setIsPatient(false)}
          >
            Therapy
          </button>
          <button
            className={`px-4 py-2 rounded w-2/3 text-md ${isPatient === true ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}
            onClick={() => setIsPatient(true)}
          >
            Patient
          </button>
        </div>
        <UserLogin isPatient={isPatient} disabled={isPatient===null}/>
      </div>
      
      {/* Right side - image */}
      <div className="hidden md:flex flex-col justify-between w-3/5 bg-gray-100">
        <div className='absolute p-4'>
          <h2 className="text-4xl text-dark-400 font-bold mb-2">Welcome to Our Service</h2>
          <p className="text-gray-700">Experience the best therapy and patient care.</p>
        </div>
        <img
          src="/assets/login.jpg"
          alt="Login"
          className="w-full h-full object-sized mr-0"
        />
        <div className='absolute w-1/2 h-4/5 flex justify-end items-end'>
            <p className="text-center text-white font-bold mt-4">Join us today and start your journey.</p>
        </div>
      </div>
    </div>
  );
}
