// Modal.jsx
import React from 'react';

const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-1/3">
      <div className="w-full flex justify-end items-end">
        <button
          className="text-gray-500 hover:text-gray-700 font-bold text-2xl"
          onClick={onClose}
        >
         X
        </button>
      </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
